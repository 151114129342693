<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CIcon name="cil-notes" />
            クーポン店舗取込
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>クーポン一覧画面からダウンロードしたCSVの「有効店舗」（U列以降）のみ編集してアップロードしてください。</CCol>
            </CRow>
            <CRow>
              <CCol>（変更しないクーポンは行から削除してください）</CCol>
            </CRow>
            <CRow v-show="hasError">
              <CCol>
                <CAlert show color="danger" style="white-space:pre-line;">
                  {{ errorMessage }}
                </CAlert>
              </CCol>
            </CRow>
            <CForm>
              <div style="margin:25px 0 20px 0;">
                <CInputFile
                  label="クーポン店舗情報CSVファイル"
                  horizontal
                  @change="onChangeFiles($event)"
                />
              </div>
            </CForm>
            <div class="table-wrapper">
              <table class="table">
                <thead>
                  <tr>
                    <th>クーポンID</th>
                    <th>クーポン名</th>
                    <th>有効店舗</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="coupon in coupons" :key="coupon.index">
                    <td>{{ coupon.couponCode }}</td>
                    <td>{{ coupon.title }}</td>
                    <td>{{ coupon.storeNames }}</td>
                 </tr>
                </tbody>
              </table>
            </div>
          </CCardBody>
          <CCardFooter>
            <CRow class="align-items-center">
              <CCol col="6" class="text-left">
                <CButton color="info" @click="submit" class="w-50">登録する</CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import firebase from '@firebase/app';
import { UserRole } from "@/common/const";
import { uuid } from "vue-uuid";

Vue.use(uuid);

export default {
  name: "ImportStoreForms",
  data() {
    return {
      couponOrigin: {
        id: "",
        couponCode: "",
        title: "",
        storeNames: "",
        stores: [],
      },
      coupons: [],
      dangerModal: false,
      errorRecordNumber: [],
      hasError: false,
      errorMessage: "",
      distributions: [],
    };
  },
  methods: {
    async getUid() {
      let uid
      uid = firebase.auth().currentUser.uid
      const userDoc = await firebase.firestore().collection('users').doc(uid).get()
      const parent_id = userDoc.data().parent_id
      if (parent_id) {
        uid = parent_id
      }
      return uid
    },
    isAdmin() {
      return this.account.role == UserRole.ADMIN;
    },
    isDistribution() {
      return this.account.role == UserRole.DISTRIBUTIONS
    },
    isMakers() {
      return this.account.role == UserRole.MAKERS
    },
    isWholeSales() {
      return this.account.role == UserRole.WHOLESALE
    },
    isRegister() {
      return this.account.role == UserRole.REGISTER
    },
    async fetchDistributions () {
      let distributionSnapshot = null;
      const uid = await this.getUid()
      const userSnapShot = await firebase.firestore()
        .collection("users")
        .doc(uid)
        .get();

      const userRole = userSnapShot.data().role;
      if (userRole == UserRole.ADMIN) {
        distributionSnapshot = await firebase.firestore().collection("users").where("role", "==", UserRole.DISTRIBUTIONS).get()
      } else if (userSnapShot.data().role == UserRole.DISTRIBUTIONS) {
        distributionSnapshot = await firebase.firestore().collection('users').where("id", "==", uid).get()
      } else {
        const uid = await this.getUid()
        const distributionSubcollection = await firebase.firestore().collection('users').doc(uid).collection('distributions').get();
        const distributionIds = distributionSubcollection.docs.map(d => d.id);
        distributionSnapshot = await firebase.firestore().collection('users').where("id", "in", distributionIds).get();
      }

      for (const distributionDoc of distributionSnapshot.docs) {
        let distribution = {}
        const distributionUser = distributionDoc.data()
        distribution.id = distributionDoc.id
        distribution.name = distributionUser.name
        const parentId = distributionUser.parent_id
        if (parentId != null && parentId != "") {
          continue;
        }

        const storesSnapshot = await firebase.firestore().collection('stores').where('companyCode', '==', distributionUser.companyCode).get()
        let stores = []
        for (const storeDoc of storesSnapshot.docs) {
          let store = {}
          store.id = storeDoc.id
          store.name = storeDoc.data().name
          stores.push(store)
        }
        distribution.stores = stores
        if (stores.length > 0) {
          this.distributions.push(distribution)
        }
      }
    },
    isDateOverlap(startDate1, endDate1, startDate2, endDate2) {
      // 日付のみで比較する
      startDate1 = startDate1 != null ? new Date(startDate1.getFullYear(), startDate1.getMonth(), startDate1.getDate()) : null;
      endDate1 = endDate1 != null ? new Date(endDate1.getFullYear(), endDate1.getMonth(), endDate1.getDate()) : null;
      startDate2 = startDate2 != null ? new Date(startDate2.getFullYear(), startDate2.getMonth(), startDate2.getDate()) : null;
      endDate2 = endDate2 != null ? new Date(endDate2.getFullYear(), endDate2.getMonth(), endDate2.getDate()) : null;

      if (startDate1 == null) {
        if (endDate1 == null) return true;
        if (startDate2 == null) return true;
        return endDate1 >= startDate2;
      } else if (endDate1 == null) {
        if (endDate2 == null) return true;
        return startDate1 <= endDate2;
      } else if (startDate2 == null) {
        if (endDate2 == null) return true;
        return startDate1 <= endDate2 && endDate2 <= endDate1;
      } else if (endDate2 == null) {
        return startDate1 <= startDate2 && startDate2 <= endDate1;
      }
      return startDate1 <= endDate2 && startDate2 <= endDate1;
    },
    async saveCoupon(element, index) {
      // クーポンの有効（無効）店舗の登録
      let couponId = element.id
      console.log(couponId)
      for (let distribution of this.distributions) {
        for (let store of distribution.stores) {
          let hasCoupon = element.stores.includes(store.name)
          if (!hasCoupon) {
            firebase.firestore().collection("stores").doc(store.id).collection('storeCoupons').doc(couponId).delete()
          } else {
            // 同じjan_codeのクーポンは同一店舗で同一期間で共存できないので古いものを削除する
            let coupon = await firebase.firestore().collection('coupons').doc(couponId).get()
            let sameJanCodeCoupons = await firebase.firestore().collection('coupons').where('jan_code', '==', coupon.get('jan_code')).get()
            for (let sameJanCodeCoupon of sameJanCodeCoupons.docs) {
              let startDate1 = coupon.get('start_date') != null ? coupon.get('start_date').toDate() : null;
              let endDate1 = coupon.get('end_date') != null ? coupon.get('end_date').toDate() : null;
              let startDate2 = sameJanCodeCoupon.get('start_date') != null ? sameJanCodeCoupon.get('start_date').toDate() : null;
              let endDate2 = sameJanCodeCoupon.get('end_date') != null ? sameJanCodeCoupon.get('end_date').toDate() : null;
              if(this.isDateOverlap(startDate1, endDate1, startDate2, endDate2)) {
                firebase.firestore().collection('stores').doc(store.id).collection('storeCoupons').doc(sameJanCodeCoupon.id).delete()
              }
            }
            let tmp = {}
            tmp.id = couponId
            firebase.firestore().collection('stores').doc(store.id).collection('storeCoupons').doc(couponId).set(tmp)
          }
        }
      }
    },
    async submit() {
      this.$store.commit("showLoading")
      this.errorMessage = "";
      this.errorRecordNumber = [];
      await this.fetchDistributions()
      for (const[index, data] of this.coupons.entries()) {
        await this.saveCoupon(data, index);
      }

      this.$store.commit("hideLoading");

      // 保存後は一覧画面に遷移
      if (this.errorRecordNumber.length === 0) {
        const link = `/coupons`;
        this.$router.push({ path: link });
      } else {
        this.hasError = true;
        for (let element in this.errorRecordNumber) {
          this.errorMessage = this.errorMessage + this.errorRecordNumber[element] + '行目はエラーのため登録されませんでした。\n';
        }
      }
    },
    onChangeFiles(files, e) {
      const reader = new FileReader();

      // csvの読み込みが完了したときに呼ばれる処理
      const loadCSV = () => {

        if (this.coupons.length != 0) {this.coupons = []}

        let allText = reader.result
        let lines = allText.split('\r\n')
        lines.forEach((element, index) => {
          if (index == 0 || element == "") {
            return
          }
          let columns = element.split(',')
          // 内容確認用に表に出力する
          this.coupons.push (
            {
              id: columns[0],
              couponCode: columns[1],
              title: columns[2],
              storeNames: columns.slice(20).join(","),
              stores: columns.slice(20),
            }
          )
        })
      }
      reader.onload = loadCSV
      reader.readAsText(files[0])
    },
  }
}
</script>
